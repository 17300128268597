<template>
  <div class="examinerManagement">
    <div class="flex-start">
      <div class="flex-left">
        <el-button size="mini"
                    icon="el-icon-plus"
                    style="margin-left: 10px;margin-bottom:10px;"
                    @click="addGroup">新增
        </el-button>

        <el-tree
          :data="folderList"
          :highlight-current="true"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :render-content="renderContent">
        </el-tree>

        <el-dialog
          title="提示"
          :visible.sync="teachingPlanDialogVisible"
          width="450px"
          >
          <el-form :model="teachingPlanForm" :rules="teachingPlanFormRules" ref="teachingPlanFormRef" label-width="100px" class="demo-ruleForm">
            <el-form-item label="名称" prop="label">
              <el-input v-model="teachingPlanForm.label"></el-input>
            </el-form-item>
          </el-form>
          <!-- <span>这是一段信息</span> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="teachingPlanDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addTeachingPlanConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="flex-right">
        <div class="flex-box">
          <div class="left">
            <el-button type="success" @click="addPlanSummary">添加</el-button>
            <!-- <el-button type="primary">下载</el-button> -->
            <!-- <el-button type="danger">批量删除</el-button> -->
          </div>
          <div class="flex-box">
            <el-input
            clearable
            @clear="queryPlanSummary(1,folder_id)"
              placeholder="请输入名称"
              class="picker"
              v-model="query.name"
            ></el-input>
            <el-select @clear="queryPlanSummary(1,folder_id)" class="picker" v-model="query.type" clearable placeholder="请选择类型">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
                </el-option>
            </el-select>

            <el-button type="primary" class="picker" @click="queryPlanSummary(1,folder_id)"
              >查 询</el-button
            >
          </div>
        </div>
        <el-table :data="addPlanSummaryTable" style="width: 100%">
          <el-table-column
            label="文件夹"
            prop="folder"
            align="center"
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column
            label="学期"
            prop="semester"
            align="center"
          ></el-table-column>
          <el-table-column
            label="级别"
            prop="typeStr"
            align="center"
          ></el-table-column>
          <el-table-column
            label="上传人"
            prop="opername"
            align="center"
          ></el-table-column>

          <el-table-column label="操作" width="270px" prop="course" align="center">
            <template slot-scope="scope">
                <div>
                    <el-button size="small" type="primary" @click="editPlanSummary(scope.row)"
                        >编辑
                    </el-button>
                    <el-button @click="downloadSubjectPlanSummary(scope.row)" size="small" type="success"
                        >下载
                    </el-button>
                    <el-button size="small" type="danger" @click="deletePlanSummary(scope.row.id)"
                        >删除
                    </el-button>
                  
                </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          layout="prev,pager,next"
          background
          :page-count="page.pageCount"
          :page-size="page.currentPage"
          @current-change="queryPlanSummary"
        ></el-pagination>

        <el-dialog
          :title="addPlanSummaryForm.id ? '修改' : '添加'"
          :visible.sync="addPlanSummaryDialogVisible"
          width="600px"
        >
          <div class="center">
            <el-form
              :model="addPlanSummaryForm"
              ref="addPlanSummaryFormRef"
              label-width="100px"
              :rules="addPlanSummaryFormRules"
            >
              <el-form-item label="名称：" prop="name">
                <el-input
                  v-model="addPlanSummaryForm.name"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="地址：" prop="url">
                <el-input
                  v-model="addPlanSummaryForm.url"
                  placeholder="请输入地址"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="级别：" prop="type">
                <el-select v-model="addPlanSummaryForm.type" clearable placeholder="请选择级别">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="照片：">
                <el-upload
                  class="upload-demo"
                  list-type="picture"
                  action="/api/base/uploadByOss.do"
                  :headers="{Authorization:token}"
                  :limit="1"
                  :on-remove="onRemoveFile"
                  ref="upload"
                  :on-success="enclosureUploadSuccess"
                  :data="{path:'planSummary',name:''}"
                  :show-file-list="true">

                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                <!-- <el-button type="primary"
                        class="load-file"
                        @click="loadFile"
                        v-if="subjectPlanSummaryForm.url">下载附件</el-button> -->
              </el-form-item>

            </el-form>
          </div>
        
          <span slot="footer" class="dialog-footer">
            <el-button @click="addPlanSummaryDialogVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="addPlanSummaryConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  
  name: "InformationPublish",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      // 文件夹列表
      folderList:[],
      teachingPlanDialogVisible:false,
      // 添加表单对象
      teachingPlanForm:{
        label:'',
        type:'',
        pid:'',
        tag:''
      },
      // 表单验证规则对象
      teachingPlanFormRules:{
        label:[{required:true, message:'请输入名称', trigger:'blur'}],
        // type:[{required:true, message:'请输入名称', trigger:'blur'}],
      },
      folder_id:'',

      page: { pageCount: 1, currentPage: 0 },
      typeList:[
          {value:1,name:'校级'},
          {value:2,name:'部门级'},
      ],
      addPlanSummaryDialogVisible:false,
      addPlanSummaryForm:{
          id:'',
          name:'',
          url:'',
          type:'',
          folder_id:''
      },
      addPlanSummaryFormRules:{
          name:[{required:true,message:'请输入名称',trigger:'blur'}],
        //   url:[{required:true,message:'请输入地址',trigger:'blur'}],
          type:[{required:true,message:'请选择级别',trigger:'blur'}],
      },
      // 查询
        query:{
            name:'',
            type:'',

        },
        addPlanSummaryTable:[],
        managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
        // roleType: false,
        role_type:'',     
        
    };
  },
  created() {
    //判断是否为管理员，1901为班级获奖页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 1901) {
        // this.roleType = true;
        this.role_type = 1
        break;
      }else{
          this.role_type = 2
      }
    }

    // 查询文件夹
    this.queryFolder()

    this.queryPlanSummary(1);
    
  },
  methods: {
    enclosureUploadSuccess(res, file) {
      this.addPlanSummaryForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.addPlanSummaryForm.url);
    },
    downloadSubjectPlanSummary(row){
      window.open(this.$fileUploadDomain + row.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.addPlanSummaryForm.url = "";
    },

    addGroup(){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = 0
        this.teachingPlanForm.type = 6
        this.teachingPlanForm.tag = 1
      })

    },
    // 查询文件夹
    queryFolder(){
      this.$post(`/base/queryLessonPlanFolder.do`,{
        type:6,
      })
      .then(res=>{
        console.log(res)
        this.folderList = res.data
      })
    },
    renderContent(h, { node, data, store }){
      return (
        <span on-click={() => this.clickTeachingPlan(data)}>
          <span>{node.label}</span>
          <span class="teachingPlan">
            <el-link
              icon="el-icon-plus"
              type="success"
              style="margin-left:15px"
              on-click={() => this.addTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-edit"
              type="primary"
              style="margin-left:15px"
              on-click={() => this.editTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-delete"
              type="danger"
              style="margin-left:15px"
              on-click={() => this.deleteTeachingPlan(data)}
            ></el-link>
          </span>
        </span>
      )
    },
    // 提交
    addTeachingPlanConfirm(){
      console.log(1)
      // return
      let data
      let url
      console.log(this.teachingPlanForm.tag)

      if(this.teachingPlanForm.tag === 1){
        data = {
          label:this.teachingPlanForm.label,
          type:this.teachingPlanForm.type,
          pid:this.teachingPlanForm.pid,
        }
        url = `/base/insertLessonPlanFolder.do`
        console.log('添加')
      }else if(this.teachingPlanForm.tag === 2){
        data = {
          id:this.teachingPlanForm.pid,
          label:this.teachingPlanForm.label,
        }
        url = `/base/updateLessonPlanFolder.do`
        console.log('编辑')
      }
      this.$post(url,data)
      .then(res=>{
        console.log(res)
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'success',
            message:'添加成功！'
          })
          // this.queryFolder(1,this.folder_id)
        }else{
          this.$message({
            type:'success',
            message:'编辑成功！'
          })
          // this.queryFolder(this.page.currentPage,this.folder_id)
        }
        this.queryFolder()
        this.teachingPlanDialogVisible = false
      })
      .catch(err=>{
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'danger',
            message:'添加失败！'
          })
        }else{
          this.$message({
            type:'danger',
            message:'编辑失败！'
          })
        }
      })
    },
    // 删除
    deleteTeachingPlan(data){
      this.$confirm('是否删除该条记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(`/base/deleteLessonPlanFolder.do`,{
            id:data.id,
            sid:data.sid,
            type:data.type
          })
          .then(res=>{
            this.queryFolder()
            this.$message({
              type:'success',
              message:'删除成功！'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    
    },
    clickTeachingPlan(data){
      // console.log(data.id)
      this.queryPlanSummary(1,data.id)
      this.addPlanSummaryForm.folder_id = data.id
    },
    // 添加
    addTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.tag = 1
      })
    },
    // 编辑
    editTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.teachingPlanForm.label = data.label
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.tag = 2
      })
    },

    // 查询计划总结
    queryPlanSummary(page,id){
        this.folder_id = id
        const that = this;
        let data = {
            indexNo: page,
            name: this.query.name,
            type: this.query.type,
            role_type:this.role_type,
            folder_id:this.folder_id
        };

      this.$post(`/base/queryPlan.do`, data)
        .then((res) => {
            console.log(res)
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
        
          that.addPlanSummaryTable = res.data.rows;
          console.log(that.addPlanSummaryTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 编辑
    editPlanSummary(row){
        // 显示对话框
      this.addPlanSummaryDialogVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.addPlanSummaryForm.id = row.id;
        this.addPlanSummaryForm.name = row.name;
        this.addPlanSummaryForm.type = row.type;
        this.addPlanSummaryForm.url = row.url;
        this.addPlanSummaryForm.folder_id = this.folder_id
        this.$refs.upload.clearFiles();
      });
    },
    // 添加总结
    addPlanSummary(){
      if(this.folder_id == undefined){
        this.$message({
          type:'info',
          message:'请先选择文件夹！'
        })
        return
      }
      this.addPlanSummaryDialogVisible = true
      this.$nextTick(() => {
          this.$refs.addPlanSummaryFormRef.resetFields();
          this.$refs.upload.clearFiles();
          this.addPlanSummaryForm.url = ''
          this.addPlanSummaryForm.id = ''
      });
    },
    // 添加计划总结确定
    addPlanSummaryConfirm(){
        this.$refs.addPlanSummaryFormRef.validate((validate) => {
        if (validate) {
            // 编辑信息表单提交
            if (this.addPlanSummaryForm.id) {
                this.$post(
                    "/base/updatePlan.do",
                    this.addPlanSummaryForm
                ).then((res) => {
                    this.$message.success("编辑成功");
                    this.addPlanSummaryDialogVisible = false;
                    // 更新当前页数据列表
                    this.queryPlanSummary(this.page.currentPage,this.folder_id);
                });
                return;
            }
            // 添加信息表单提交
            const data = {
                name: this.addPlanSummaryForm.name,
                type: this.addPlanSummaryForm.type,
                url: this.addPlanSummaryForm.url,
                folder_id:this.addPlanSummaryForm.folder_id,
            };
            console.log(data);
            this.$post("/base/insertPlan.do", data)
            .then((res) => {
                this.$message.success("添加成功");
                this.addPlanSummaryDialogVisible = false;
                this.queryPlanSummary(1,this.folder_id);
                console.log(res);
            })
            .catch((err) => {
                // that.$message.error(err.message.data);
            });
        }
      });
    },
    deletePlanSummary(id){
        const that = this;
        this.$confirm("是否删除该条记录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(() => {
            this.$post(`/base/deletePlan.do?id=${id}`)
            .then((res) => {
                that.$message({
                    type: "success",
                    message: "删除成功!",
                });
                that.queryPlanSummary(this.page.currentPage,this.folder_id);
            })
            .catch((err) => {
                that.$message.error(err.message);
            });
        })
        .catch(() => {
            this.$message({
                type: "info",
                message: "已取消删除",
            });
        });
    },

  },
};
</script>

<style>
.teachingPlan{
  display: none;
}

.el-tree-node__content:hover .teachingPlan {
  display: inline;
}

</style>

<style scoped>
.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-left{
  width: 20%;
  margin-top:20px;
}

.flex-right{
  width: 80%;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
